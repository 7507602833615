import { Logger } from '@melio/platform-logger';

import { defaultConfig } from '@/configByPartner/default.config';
import version from '@/version';

// a workaround to get the ViteMode due to how we build the project.
// ideally, it should be import.meta.env.MODE but we use `npm run build` for all envs
export const getViteEnvironment = (): 'development' | 'production' | 'alpha' => {
  if (import.meta.env?.DEV) {
    return 'development';
  }
  if (defaultConfig.production) {
    return 'production';
  }
  return 'alpha';
};

export const InitRemoteLogger = () => {
  const environment = getViteEnvironment();
  Logger.init({
    environment,
    fullStoryOrgId: import.meta.env.VITE_FULL_STORY_ORG_ID?.toString(),
    release: version,
    consoleEnabled: environment !== 'production',
    remoteEnabled: environment !== 'development',
    tracesSampleRate: 0.03,
    service: 'platform-app',
  });
};
