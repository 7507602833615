import { merge } from 'lodash';

import { fiservConfigBase } from './fiserv.config';
import { extendDefaultConfig, PartnerConfigByEnv } from './partnerConfig.utils';

export const fiservUsBankConfigBase: PartnerConfigByEnv = merge<PartnerConfigByEnv>({}, fiservConfigBase, {
  default: {
    settings: {
      payment: {
        scheduling: {
          fundingSourceTypeSelectionEnabled: false,
        },
      },
      embeddedExperience: {
        maxWidth: '1200px',
        singleScrollOwnedByHost: false,
      },
      AllowCaratIntegration: {
        enabled: false,
      },
    },
  },
  dev: {},
  prod: {},
});

export const fiservUsBankConfig = extendDefaultConfig(fiservUsBankConfigBase);
